@import "vars";

body {
  background-color: $white;
  color: $black;
}

a {
  color: $black;
}

a:hover, a:focus, a:visited {
  color: $black;
  text-decoration: none;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  height: 50px;
  padding: 15px 30px 15px 30px;

  .nav {
    .nav-item {
      .nav-link {
        color: $gray;
        margin: 0 5px;
      }
    }

    .nav-item {
      &.active, &:hover {
        .nav-link {
          color: $highlight-color;
        }
      }
    }
  }
}

.portrait {
  min-height: calc(100vh - 30px);
  max-width: calc(100vw - 30px);
  margin: 0 auto;
  margin-top: 15px;
  border: 10px solid $black;
  border-radius: 3px;
  background-color: $white;
  padding-top: 15px;
  padding-bottom: 15px;

  h1 {
    font-weight: 300;
  }

  h3 {
    small {
      color: $gray;
    }
  }
}

.portrait-image {
  font-size: 14rem;
  text-align: center;
}
@media screen and (min-width: 768px){
  .portrait-image {
    font-size: 30rem !important;
    text-align: center;
  }
}

@media screen and (min-width: 992px){
  .portrait-image {
    font-size: 24rem !important;
    text-align: center;
  }
}


@media screen and (min-width: 1200px){
  .portrait-image {
      font-size: 30rem !important;
      text-align: center;
  }
}


.comment {
  margin: 25px 0;
  border: 1px solid $gray-light;
  padding: 15px;
}

.btn-outline-primary {
  color: $black;
  background-image: none;
  background-color: transparent;
  border-color: transparent;

  &:hover,&:focus,&:active,&:visited,&.active,&:active:hover {
    color: lighten($black, 20%);
    background-image: none;
    background-color: $white;
    border-color: transparent;
    outline: none;
  }
}

.btn-primary {
  color: $white;
  background-color: $black;
  border-color: $black;
}

.btn-primary:hover {
  color: #fff;
  background-color: lighten($black,10%);
  border-color: lighten($black,10%);
}

.fr-view {
  min-height: 100px !important;
}

#comment-createdat, #comment-name {
  color: $gray;
}

#comment-message {
  font-size: 120%;
}

#scroll-top {
  display: none;
  border: 1px solid $gray;
  border-radius: 5px;
  background-color: $body-bg-color;
}

.bottom-nav {
  margin-top: 500px;
  padding-bottom: 50px !important;
}

.quotes {
  min-height: 125px;
}