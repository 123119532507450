$gray: #96999C !default;
$gray-light: #e8e8e8 !default;
$gray-dark: #34393F !default;
$orange: #FA7D19 !default;
$white: #FFFFFF !default;
$black: #000000 !default;

$body-bg-color: $gray-light !default;
$main-color: $gray-dark !default;
$highlight-color: $orange !default;

$sidebar-width: 150px !default;